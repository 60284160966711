'use client'

import Image, { ImageProps } from 'next/image'
import React, { useState } from 'react'

const ImageWithArweaveFallbackClient = (props: ImageProps) => {
  let { src } = props
  const { ...rest } = props

  if (typeof src === 'string') {
    src = (src as string)
      .replace('www.', '')
      .replace('arweave.net', 'gateway.irys.xyz')
  }

  const [imgSrc, setImgSrc] = useState(src)

  return (
    <Image
      {...rest}
      src={imgSrc}
      onError={() => {
        setImgSrc(`${src}?fallback=true`)
      }}
    />
  )
}

export default ImageWithArweaveFallbackClient
