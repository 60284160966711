import { ComputeBudgetProgram, Connection } from '@solana/web3.js'

const BASE_PRIORITY_FEE = 5000

export const calculatePriorityFee = async (connection: Connection) => {
  const recentPrioritizationFees =
    await connection.getRecentPrioritizationFees()

  if (!recentPrioritizationFees) {
    throw new Error('Failed to get recent prioritization fee')
  }

  const totalFees = recentPrioritizationFees.reduce(
    (total, current) => total + current.prioritizationFee,
    0,
  )

  return (
    Math.ceil(totalFees / recentPrioritizationFees.length) + BASE_PRIORITY_FEE
  )
}

export const addPriorityFeeIx = (fee: number) => {
  return ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: fee,
  })
}
