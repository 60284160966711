'use client'

import Link from 'next/link'
import { useContext, useEffect, useState } from 'react'
import CreateEntryModal from '@/components/CreateEntryModal'
import EventDiv from '@/components/EventDiv'
import LoginModal from '@/components/LoginModal'
import Button from '@/components/tokens/Button'
import Wallet from '@/contexts/WalletContext'
import { WalletContextValues } from '@/contexts/WalletContext'
import { ButtonColor, ButtonFont, ButtonVariant } from '@/lib/types'

export default function HomepageHeader() {
  const { sessionSignature } = useContext(Wallet.Context) as WalletContextValues
  const [forceOpen, setForceOpen] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  useEffect(() => {
    const sessionSignatureLocalStorage =
      localStorage.getItem('sessionSignature')

    if (sessionSignatureLocalStorage) {
      const publicKey = JSON.parse(sessionSignatureLocalStorage).publicKey

      if (publicKey) {
        setIsLoggedIn(true)
      }
    }
  }, [])

  return (
    <>
      {!isLoggedIn && !sessionSignature && (
        <div>
          <h1 className="display-1">A new internet of music</h1>
          <div className="mt-20 flex flex-row gap-x-12 text-14">
            <LoginModal labelText={'Create account'} />

            <Link href="/about">
              <Button
                variant={ButtonVariant.Small}
                color={ButtonColor.Black}
                font={ButtonFont.Body1}
                label="Learn more"
              />
            </Link>
            <EventDiv
              action="homepage_upload_button_selected"
              category="interaction"
              params={{
                pathname: '/',
              }}
            >
              <Button
                variant={ButtonVariant.Small}
                color={ButtonColor.Black}
                font={ButtonFont.Body1}
                label="Upload"
                onClick={() => setForceOpen(true)}
              />
            </EventDiv>
          </div>
        </div>
      )}
      <CreateEntryModal
        modalLabel="Upload"
        modalLabelVariant={ButtonVariant.Small}
        forceOpen={forceOpen}
        setForceOpen={setForceOpen}
        hideTrigger={true}
      />
    </>
  )
}
