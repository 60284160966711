'use client'

import clsx from 'clsx'
import React from 'react'
import { Release, SearchResultsRelease, View } from '@/lib/types'

type ArchivedFlagProps = {
  release: Release | SearchResultsRelease
  view: View
  hasAccess?: boolean | null | undefined
}

export default function ArchivedFlag({
  release,
  view,
  hasAccess,
}: ArchivedFlagProps) {
  if (!release?.archived || (release.archived && !hasAccess)) {
    return null
  }

  return (
    <div
      className={clsx(
        view === View.Grid && 'mt-4 ',
        view === View.List && 'mt-4',
        'flex cursor-default flex-row items-center',
      )}
    >
      <p className="ui-1 cursor-default select-none text-grey-45">Archived</p>
    </div>
  )
}
