import { useRouter } from 'next/navigation'
import { useContext, useEffect, useState } from 'react'
import Modal from '@/components/Modal'
import Wallet from '@/contexts/WalletContext'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  ModalType,
} from '@/lib/types'
import AccountVerificationSettings from '../profiles/[accountPublicKey]/components/AccountVerificationSettings'

type VerificationFlowModal = {
  labelText?: string
  backgroundOverride?: boolean
  loginModalLabelPromptString?: string
  redirectOnLogin?: string
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
  forceOpen?: boolean
  setForceOpen?: React.Dispatch<React.SetStateAction<boolean>>
  hideTrigger?: boolean
  labelTextColor?: ButtonColor
  labelColor?: ButtonColor
  onClick?: () => void
  verificationFlowActive?: boolean
  setVerificationFlowActive?: React.Dispatch<React.SetStateAction<boolean>>
  verificationAttempted?: boolean | undefined
  setVerificationAttempted?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined
  soundcloudToken: string | undefined
  soundcloudHandle: string | undefined
  twitterHandle: string | undefined
  twitterToken: string | undefined
}

export default function VerificationFlowModal({
  labelText,
  backgroundOverride,
  loginModalLabelPromptString,
  setForceOpen,
  forceOpen,
  hideTrigger,
  setMenuOpen,
  labelTextColor = ButtonColor.Black,
  labelColor = ButtonColor.Black,
  onClick,
  verificationFlowActive,
  setVerificationFlowActive,
  soundcloudHandle,
  soundcloudToken,
  twitterHandle,
  twitterToken,
  setVerificationAttempted,
  verificationAttempted,
}: VerificationFlowModal) {
  const [isOpen, setIsOpen] = useState(forceOpen || false)
  const router = useRouter()
  const { profile } = useContext(Wallet.Context)
  useEffect(() => {
    if (forceOpen) {
      setIsOpen(true)
    }
  }, [forceOpen])

  useEffect(() => {
    if (!isOpen && setForceOpen) {
      setForceOpen(false)
    }

    if (isOpen && onClick) {
      onClick()
    }
  }, [isOpen])

  const handleClose = () => {
    setIsOpen(false)
    setVerificationFlowActive && setVerificationFlowActive(false)

    if (setForceOpen) {
      setForceOpen(false)
    }

    if (setMenuOpen) {
      setMenuOpen(false)
    }

    router.push(`/profiles/${profile!.handle}`)
  }

  const responsiveness = loginModalLabelPromptString
    ? ButtonResponsiveness.MinW
    : ButtonResponsiveness.Full

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        label={labelText}
        modalHeader="Account Verification"
        modalLabelVariant={ButtonVariant.Small}
        modalLabelResponsiveness={responsiveness}
        modalType={ModalType.Login}
        modalLabelFont={ButtonFont.Body1}
        modalLabelTextColor={labelTextColor}
        modalLabelColor={labelColor}
        disableKeydownSubmit={true}
        backgroundOverride={backgroundOverride}
        closeCallback={handleClose}
        hideTrigger={hideTrigger}
      >
        <AccountVerificationSettings
          verificationFlowActive={verificationFlowActive}
          setVerificationFlowActive={setVerificationFlowActive}
          soundcloudHandle={soundcloudHandle}
          soundcloudToken={soundcloudToken}
          twitterHandle={twitterHandle}
          twitterToken={twitterToken}
          verificationAttempted={verificationAttempted}
          setVerificationAttempted={setVerificationAttempted}
        />
      </Modal>
    </>
  )
}
