'use client'

import clsx from 'clsx'
import Link from 'next/link'
import ArchivedFlag from '@/app/(main)/releases/[releasePublicKey]/components/ArchivedFlag'
import ReleasePlayButton from '@/components/ReleasePlayButton'
import {
  HubRelease,
  ListBoxView,
  PublicKeyString,
  Release,
  ScheduledRelease,
  SearchResultsRelease,
  View,
} from '@/lib/types'
import formatReleaseData from '@/lib/utils/formatReleaseData'
import { useReleaseAccess } from '@/lib/utils/useReleaseAccess'
import CollectedFlag from './CollectedFlag'
import ImageWithArweaveFallbackClient from './ImageWithArweaveFallbackClient'
import ReleaseListBox from './ReleaseListBox'

type ReleaseGridItemProps = {
  release: Release | SearchResultsRelease | ScheduledRelease
  isHubAuthority?: boolean
  hubPublicKey?: PublicKeyString
}

export default function ReleaseGridItem({
  release,
  isHubAuthority,
  hubPublicKey,
}: ReleaseGridItemProps) {
  const { releaseLinkString, releaseImageString, releaseTitleString } =
    formatReleaseData(release)

  const { hasAccess } = useReleaseAccess(release)
  const hasMetadata = 'metadata' in release && release.metadata

  if (release?.archived && !hasAccess) {
    return <ArchiveReleaseGridItem />
  }

  if ((release as HubRelease)?.visible === false && !isHubAuthority) {
    return null
  }

  if (!release) return null

  return (
    <div key={release.publicKey} className="h-full">
      <div className="group relative">
        <Link
          href={`/releases/${releaseLinkString}`}
          className={clsx(
            !release.publicKey &&
              'disableHighlight pointer-events-none touch-none',
          )}
          onClick={(e) => {
            if (!release.publicKey) {
              e.preventDefault()

              return
            }
          }}
        >
          <ImageWithArweaveFallbackClient
            src={releaseImageString}
            alt={releaseTitleString}
            width={353}
            height={353}
            className="imageBorder box-border h-full w-full"
            priority
            loading="eager"
          />
        </Link>{' '}
        {hasMetadata && release.publicKey && (
          <div className="absolute bottom-0 left-0 hidden h-auto w-min flex-row items-end gap-x-12 opacity-0 group-hover:opacity-100 md:flex">
            <div className="absolute bottom-12 left-12 flex flex-row gap-x-12">
              <abbr title={'Play release'} className="no-underline">
                <ReleasePlayButton release={release as Release} />
              </abbr>
              <ReleaseListBox
                release={release as Release}
                view={ListBoxView.Grid}
                variant="gridItem"
                isHubAuthority={isHubAuthority}
                hubPublicKey={hubPublicKey}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <Link
          href={`/releases/${releaseLinkString}`}
          className={clsx(
            'body-1 mt-8  line-clamp-2 max-h-[40px] min-h-[20px]  md:mt-12 md:hover:opacity-70',
            !release.publicKey &&
              'disableHighlight pointer-events-none touch-none',
          )}
        >
          <p className="line-clamp-2 overflow-hidden ">{releaseTitleString}</p>
        </Link>

        {hasMetadata && release.hub && (
          <Link
            href={`/hubs/${release.hub.handle}`}
            className="body-1 line-clamp-1 overflow-hidden truncate text-blue-82 hover:opacity-80"
          >
            {release.hub.data.displayName}
          </Link>
        )}
        {hasMetadata && !release.hub && (
          <Link
            href={`/profiles/${release?.publisherAccount?.handle}`}
            className="body-1 line-clamp-1 overflow-hidden truncate text-grey-45 hover:opacity-80"
          >
            {release?.publisherAccount?.displayName}
          </Link>
        )}
      </div>
      {'releaseDate' in release && release.releaseDate && (
        <p className="body-1 text-grey-45">
          {new Date(release.releaseDate).toLocaleString()}
        </p>
      )}
      <CollectedFlag release={release} view={View.Grid} />
      <ArchivedFlag release={release} view={View.Grid} hasAccess={hasAccess} />
    </div>
  )
}

function ArchiveReleaseGridItem() {
  return (
    <div>
      <div className="aspect-h-9 aspect-w-9 relative w-full bg-grey-3">
        <div className="absolute inset-0 flex items-center justify-center">
          <p className="ui-1 cursor-default select-none text-grey-45">
            Archived release
          </p>
        </div>
      </div>
      <div className="body-1 h-24 bg-none md:h-28" />
    </div>
  )
}
