'use client'

import { useContext, useState } from 'react'
import AudioContext from '@/contexts/AudioContext'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { toggleHubContentVisibility } from '@/lib/hub/toggleHubContentVisibility'
import { ListBoxView, PlayType, PublicKeyString, Release } from '@/lib/types'
import ReleaseRepostModal from './ReleaseRepostModal'
import Ellipsis from './tokens/Ellipsis'

export default function ReleaseListBox(props: {
  release: Release
  view: ListBoxView
  variant?: string
  index?: number
  isTrack?: boolean
  isHubAuthority?: boolean
  hubPublicKey?: PublicKeyString
}) {
  const {
    release,
    view,
    variant,
    index,
    isTrack = false,
    isHubAuthority,
    hubPublicKey,
  } = props

  const { wallet } = useContext(Wallet.Context)
  const { addToast } = useContext(Toast.Context)
  const { update } = useContext(Update.Context)
  const [showRepost, setShowRepost] = useState(false)

  const { addReleaseToQueue, addTrackToQueue, playRelease } = useContext(
    AudioContext.Context,
  )

  let releaseOptions: {
    [key: string]: {
      label: string
      type: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: (...args: any[]) => void
    }
  } = {
    playNext: {
      label: 'Play next',
      type: 'playNext',
      callback: (release: Release) => {
        addReleaseToQueue(release, PlayType.Next)
      },
    },
    playLater: {
      label: 'Play later',
      type: 'playLater',
      callback: (release: Release) => {
        addReleaseToQueue(release, PlayType.Later)
      },
    },
    divider: {
      label: '',
      type: 'divider',
      callback: () => null,
    },
    repostRelase: {
      label: 'Repost',
      type: 'repostRelease',
      callback: () => {
        setShowRepost(true)
      },
    },
  }

  const trackOptions: {
    [key: string]: {
      label: string
      type: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: (...args: any[]) => void
    }
  } = {
    playNow: {
      label: 'Play now',
      type: 'playNow',
      callback: (release: Release) => {
        playRelease(release, index)
      },
    },
    playNext: {
      label: 'Play next',
      type: 'playNext',
      callback: (release: Release, index: number) => {
        addTrackToQueue(release, index + 1, PlayType.Next)
      },
    },
    playLater: {
      label: 'Play later',
      type: 'playLater',
      callback: (release: Release, index: number) => {
        addTrackToQueue(release, index + 1, PlayType.Later)
      },
    },
  }

  if (isHubAuthority) {
    releaseOptions = {
      ...releaseOptions,
      hideFromHub: {
        label: 'Hide from hub',
        type: 'hideFromHub',
        callback: async () => {
          await handleHideFromHub()
        },
      },
    }
  }

  const handleHideFromHub = async () => {
    try {
      addToast({
        message: 'Hiding release from hub',
        variant: 'loading',
      })

      const result = await toggleHubContentVisibility({
        hubPublicKey,
        releasePublicKey: release.publicKey,
        wallet,
      })

      if (result?.error) {
        addToast({
          message: 'Error hiding release from hub',
          variant: 'error',
        })
      } else {
        addToast({
          message: 'Release hidden from hub',
          variant: 'success',
        })
        update()
      }
    } catch (error) {
      console.error(error)
      addToast({
        message: 'Error hiding release from hub',
        variant: 'error',
      })
    }
  }

  if (variant === 'header') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete releaseOptions?.divider
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete releaseOptions?.repostRelase
  }

  const options = () => {
    if (isTrack || view === ListBoxView.List) {
      return trackOptions
    } else {
      return releaseOptions
    }
  }

  return (
    <>
      <Ellipsis
        options={options()}
        value={release}
        view={view}
        variant={variant}
        parentIndex={index}
        align={view === ListBoxView.List ? 'left' : undefined}
      />

      {showRepost && (
        <ReleaseRepostModal
          release={release}
          forceOpen={true}
          hideTrigger={true}
        />
      )}
    </>
  )
}
