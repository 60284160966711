'use client'

import axios from 'axios'
import { useSearchParams } from 'next/navigation'
import { useContext, useEffect, useState } from 'react'
import Wallet from '@/contexts/WalletContext'
import VerificationFlowModal from './VerificationFlowModal'

export default function VerificationCallbackListener() {
  const [soundcloudHandle, setSoundcloudHandle] = useState<string | undefined>()
  const [soundcloudToken, setSoundcloudToken] = useState<string | undefined>()
  const [twitterHandle, setTwitterHandle] = useState<string | undefined>()
  const [twitterToken, setTwitterToken] = useState<string | undefined>()
  const [verificationCode, setVerificationCode] = useState<string | null>()
  const [verficationAttempted, setVerificationAttempted] = useState(false)

  const [verificationCodeSource, setVerificationCodeSource] = useState<
    string | null
  >()

  const [verificationFlowActive, setVerificationFlowActive] = useState(false)
  const { wallet } = useContext(Wallet.Context)
  const searchParams = useSearchParams()
  useEffect(() => {
    setVerificationCode(searchParams.get('code'))

    const getHandle = async () => {
      try {
        switch (verificationCodeSource) {
          case 'soundcloud':
            const scResponse = await axios.post(
              `${process.env.NINA_ID_ENDPOINT}/sc/user`,
              {
                code: verificationCode,
              },
            )

            if (scResponse.data) {
              setSoundcloudHandle(scResponse.data.username)
              setSoundcloudToken(scResponse.data.token.access_token)
            }

            break
          case 'twitter':
            const twResponse = await axios.post(
              `${process.env.NINA_ID_ENDPOINT}/tw/user`,
              {
                code: verificationCode,
              },
            )

            if (twResponse.data) {
              console.warn('twResponse.data>> ', twResponse.data)
              setTwitterHandle(twResponse.data.username)
              setTwitterToken(twResponse.data.token)
            }
        }
      } catch (error) {
        console.warn('error in get handle>> ', error)

        return
      }
    }

    if (
      verificationCode &&
      verificationCodeSource &&
      wallet.publicKey &&
      !verficationAttempted
    ) {
      setVerificationFlowActive(!!searchParams.get('code'))

      getHandle()
    }
  }, [searchParams, verificationCode, verificationCodeSource, wallet.publicKey])

  useEffect(() => {
    if (searchParams.get('code')) {
      setVerificationFlowActive(true)

      setVerificationCodeSource(localStorage.getItem('verificationCodeSource'))
    }
  }, [])

  return (
    <>
      <VerificationFlowModal
        verificationFlowActive={verificationFlowActive}
        setVerificationFlowActive={setVerificationFlowActive}
        soundcloudHandle={soundcloudHandle}
        soundcloudToken={soundcloudToken}
        twitterHandle={twitterHandle}
        twitterToken={twitterToken}
        forceOpen={verificationFlowActive}
        hideTrigger={true}
        setVerificationAttempted={setVerificationAttempted}
        verificationAttempted={verficationAttempted}
      />
    </>
  )
}
