import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Wallet from '@/contexts/WalletContext'
import { Release, ScheduledRelease, SearchResultsRelease } from '../types'

export const useReleaseAccess = (
  release: Release | SearchResultsRelease | ScheduledRelease,
) => {
  const {
    userHasFavoritedItem,
    userHasRelease,
    userIsRevenueShareRecipient,
    profile,
    wallet,
  } = useContext(Wallet.Context)

  const [hasAccess, setHasAccess] = useState<null | undefined | boolean>()

  const isPublisher = useMemo(
    () => (release as Release).publisher === wallet?.publicKey?.toBase58(),
    [release, wallet],
  )

  const isFavorited = useMemo(
    () => userHasFavoritedItem(release.publicKey),
    [release, userHasFavoritedItem],
  )

  const isCollected = useMemo(
    () => userHasRelease(release.publicKey),
    [release, userHasRelease],
  )

  const isRecipient = useMemo(
    () => userIsRevenueShareRecipient(release.publicKey),
    [release, userIsRevenueShareRecipient],
  )

  const checkAccess = useCallback(() => {
    return profile && (isPublisher || isCollected || isFavorited || isRecipient)
  }, [profile, isPublisher, isCollected, isFavorited, isRecipient])

  useEffect(() => {
    setHasAccess(checkAccess())
  }, [checkAccess])

  return {
    hasAccess,
    isPublisher,
    isFavorited,
    isCollected,
    isRecipient,
  }
}
