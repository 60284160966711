import Nina from '@nina-protocol/js-sdk'
import { WalletContextState } from '@solana/wallet-adapter-react'
import axios from 'axios'
import { PublicKeyString } from '../types'
import { TxResponse } from '../types'
import initSdk from '../utils/initSdk'

type ToggleHubContentVisibilityProps = {
  hubPublicKey?: PublicKeyString
  releasePublicKey?: PublicKeyString
  postPublicKey?: PublicKeyString
  wallet: WalletContextState
}

export const toggleHubContentVisibility = async ({
  hubPublicKey,
  releasePublicKey,
  postPublicKey,
  wallet,
}: ToggleHubContentVisibilityProps): Promise<
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error?: any
      message: string
      txid?: string | undefined
    }
  | {
      error: string
    }
  | undefined
> => {
  let contentAccountPublicKey
  let type

  if (!wallet || !hubPublicKey) {
    return
  }

  if (releasePublicKey) {
    type = 'Release'
    contentAccountPublicKey = releasePublicKey
  } else if (postPublicKey) {
    type = 'Post'
    contentAccountPublicKey = postPublicKey
  }

  try {
    await initSdk(wallet)

    const { tx, hubReleasePublicKey } =
      await Nina.Hub.hubContentToggleVisibility(
        hubPublicKey,
        contentAccountPublicKey,
        type,
        true,
      )

    const txResponse: TxResponse = await axios.post(
      `${process.env.NINA_FILE_SERVICE_ENDPOINT}/tx`,
      {
        tx,
        hubPublicKey,
        hubReleasePublicKey,
        type,
      },
    )

    await axios.get(
      `${process.env.NINA_API_ENDPOINT}/hubs/${hubPublicKey}/hubReleases/${hubReleasePublicKey}`,
    )
    const { data } = txResponse

    return data
  } catch (error) {
    console.error(error)

    return {
      error: `Error toggling hub content visibility: ${error}`,
    }
  }
}
