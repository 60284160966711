'use client'

import { useRouter } from 'next/navigation'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import Form from '@/components/Form'
import FileService from '@/contexts/FileServiceContext'
import Wallet from '@/contexts/WalletContext'
import { fetchHubsForAccount } from '@/lib/hub/fetchHubsForAccount'
import { fetchReleasesPublishedByAccount } from '@/lib/release/fetchReleasesPublishedByAccount'
import {
  FileExtended,
  FlowType,
  FormField,
  FormSteps,
  Hub,
  ImageCropProps,
  ReleaseCreateFormFieldData,
} from '@/lib/types'
import { convertFieldsToObject } from '@/lib/utils/convertFieldsToObject'
import getCroppedImage from '@/lib/utils/cropImage'
import { reusedConsts } from '@/lib/utils/reusedConsts'
import { validateField } from '@/lib/utils/validateField'
import { validateFormSchema } from '@/lib/utils/validateFormSchema'

// import { validateMultiFileInput } from '@/lib/utils/validateMultiFileInput'

type FormFieldNoValue = Omit<FormField, 'value'>

type ArtWorkFieldProps = FormFieldNoValue & {
  value: ImageCropProps
}

const IMAGE_SIZE_LIMIT = reusedConsts.IMAGE_SIZE_LIMIT

const formSteps: FormSteps = {
  0: {
    fields: ['tracks'],
    buttonLabel: 'Continue',
    hideButtons: true,
    onChangeCallback: async (fields, setFields) => {
      const validationSchema = z.object({
        tracks: z.instanceof(File).array().nonempty({
          message: 'At least one file is required to create a release',
        }),
      })

      const validationResult = validateFormSchema(
        validationSchema,
        fields,
        setFields,
      )

      return validationResult.success
    },
  },
  1: {
    fields: ['title', 'hub', 'artwork', 'description', 'tags', 'tracks'],
    buttonLabel: 'Continue',
    hideBackButton: true,
    onChangeCallback: async (fields, setFields) => {
      const trackFields = fields.find((field) => field.name === 'tracks')

      const validationSchema = z.object({
        title: z.string().min(1, { message: 'Title is required' }).max(500),
        artwork: z.object({
          imageSrc: z.string(),
          croppedAreaPixels: z.object({
            x: z.number(),
            y: z.number(),
            width: z.number(),
            height: z.number(),
          }),
        }),
        // tracks: z.instanceof(File).array().nonempty({
        //   message: 'At least one file is required to create a release',
        // }),
        tracks:
          trackFields?.value.length > 1
            ? z.array(
                z.object({
                  title: z.string().nonempty({
                    message: 'Title is required for each track',
                  }),
                  type: z.enum(['audio/mpeg', 'audio/wav', 'audio/x-wav'], {
                    errorMap: () => ({
                      message: 'File must be an wav or mp3',
                    }),
                  }),
                }),
              )
            : z.instanceof(File).array().nonempty({
                message: 'At least one file is required to create a release',
              }),
      })

      const validationResult = validateFormSchema(
        validationSchema,
        fields,
        setFields,
      )

      if (!validationResult.success) return false

      if (trackFields?.value.length > 1) {
        //Non zod validation

        const fileErrorArray: (string | undefined)[] = []

        const validateTracksResult = trackFields?.value.map(
          (track: FileExtended) => {
            if (track.title === undefined || track.title === '') {
              fileErrorArray.push('Title is required for each track')

              return false
            }

            fileErrorArray.push(undefined)

            return true
          },
        )

        if (validateTracksResult.includes(false)) {
          //tracks are invalid
          setFields((prevFields) => {
            const prevFieldsCopy = [...prevFields]

            const trackFields = prevFieldsCopy.find(
              (field) => field.name === 'tracks',
            )

            if (trackFields) {
              trackFields.error = fileErrorArray
              // pass error for each track
            }

            return prevFieldsCopy
          })

          return false
        } else {
          //SINGLE TRACK
          setFields((prevFields) => {
            const prevFieldsCopy = [...prevFields]

            const trackFields = prevFieldsCopy.find(
              (field) => field.name === 'tracks',
            )

            if (trackFields) {
              trackFields.error = undefined
            }

            return prevFieldsCopy
          })
        }
      }

      //Generate catalog number
      const lastReleaseCatalogNumber = localStorage.getItem(
        'lastReleaseCatalogNumber',
      )

      const catalogNumberField = fields.find(
        (field) => field.name === 'catalogNumber',
      )

      const titleField = fields.find((field) => field.name === 'title')

      if (!lastReleaseCatalogNumber && titleField && catalogNumberField) {
        catalogNumberField.value = formatAndIncrementCatalogNumber(
          titleField.value,
        )
      }

      const artworkField = fields?.find(
        (field: FormField) => field.name === 'artwork',
      ) as ArtWorkFieldProps

      const { imageSrc, croppedAreaPixels, file } = artworkField.value
      let croppedImageResult: string | undefined

      if (imageSrc && croppedAreaPixels) {
        croppedImageResult = (await getCroppedImage(
          imageSrc as string,
          croppedAreaPixels,
          file?.name,
          file?.type,
        )) as string

        setFields((prevFields) => {
          const prevFieldsCopy = [...prevFields]

          const artworkField = prevFieldsCopy.find(
            (field) => field.name === 'artwork',
          )

          if (artworkField?.value) {
            artworkField.value.croppedImage = croppedImageResult
          }

          return prevFieldsCopy
        })
      }

      return validationResult.success
    },
  },
  2: {
    fields: [
      'price',
      'editionType',
      'editionSize',
      'customEditionSize',
      'catalogNumber',
      'scheduleRelease',
      'conversionQuality',
      'date',
      'isUsdc',
    ],
    buttonLabel: 'Continue',
    onChangeCallback: async (fields, setFields) => {
      const editionTypeField = fields.find(
        (field) => field.name === 'editionType',
      )

      const editionSizeField = fields.find(
        (field) => field.name === 'editionSize',
      )

      const editionType = editionTypeField ? editionTypeField.value : undefined
      const editionSize = editionSizeField ? editionSizeField.value : undefined

      const limitedEditionSchema = z.enum(['5', '10', '50', '100', 'custom'], {
        errorMap: () => ({ message: 'Edition size is required' }),
      })

      const validationSchema = z.object({
        price: z.string(),
        editionType: z.string(),
        editionSize:
          editionType === 'limitedEdition' ? limitedEditionSchema : z.any(),
        customEditionSize:
          editionSize === 'custom'
            ? z.string().min(1, {
                message: 'Edition size cannot be empty',
              })
            : z.any(),
        catalogNumber: z
          .string()
          .min(1, { message: 'Catalog number required' })
          .max(20),
        scheduleRelease: z.boolean(),
      })

      const validationResult = validateFormSchema(
        validationSchema,
        fields,
        setFields,
      )

      return validationResult.success
    },
  },
  3: {
    fields: ['review'],
    buttonLabel: 'Upload',
    onChangeCallback: async () => {
      return true
    },
  },
}

const formatAndIncrementCatalogNumber = (inputString: string) => {
  const formattedString = inputString
    ?.replace(/^([^\-]{0,8}).*/, '$1')
    .replace(/[\/\s]/g, '-')
    .toUpperCase()

  const regex = /(\d+)$/
  const match = formattedString?.match(regex)

  if (match) {
    const number = parseInt(match[1], 10)
    const incrementedNumber = number + 1
    const paddedNumber = String(incrementedNumber)?.padStart(3, '0')
    const resultString = formattedString?.replace(regex, paddedNumber)

    return resultString
  } else {
    return formattedString + '001'
  }
}

type ReleaseCreateFormProps = {
  setHeaderSteps: React.Dispatch<React.SetStateAction<number>>
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  closeModal: () => void
  accountHubs: Hub[]
  setAccountHubs: React.Dispatch<React.SetStateAction<Hub[]>>
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
  isParentOpen: boolean
}

const SOL_PUBLISHERS = [
  '3Z8cBM8XT5CBJwVJzpZo6ikkinYma1EEqN2o39ZFYApZ', // COH
]

export default function ReleaseCreateForm({
  setHeaderSteps,
  currentStep,
  setCurrentStep,
  closeModal,
  accountHubs, // get the users hubs in scope to render names where needed -- TODO move to context
  setAccountHubs,
  setHasUnsavedChanges,
  isParentOpen,
}: ReleaseCreateFormProps) {
  const { wallet } = useContext(Wallet.Context)
  const titleFieldRef = useRef<HTMLInputElement>(null)
  const artworkFieldRef = useRef<HTMLInputElement>(null)
  const tracksFieldRef = useRef<HTMLInputElement>(null)

  const isUsdc =
    wallet?.publicKey?.toBase58() &&
    SOL_PUBLISHERS.includes(wallet.publicKey.toBase58())
      ? false
      : true

  const defaultReleaseCreateFields = () => {
    return [
      {
        name: 'title',
        label: 'Release Title',
        type: 'text',
        placeholder: 'Enter a title for your release',
        required: true,
        hint: 'Suggested: Artist - Title',
        inputProps: {
          inputRef: titleFieldRef,
          validate: (value: string) => {
            if (value?.length > 0) {
              return true
            }

            return 'Required'
          },
        },
      },
      {
        name: 'hub',
        label: 'Hub',
        type: 'select',
        placeholder: 'Select a hub',
        required: false,
        hint: 'This cannot be changed',
        inputProps: {
          options: [],
          accentColor: 'text-blue-82',
        },
      },
      {
        name: 'artwork',
        label: 'artwork',
        type: 'imageUpload',
        required: true,
        value: undefined,
        inputProps: {
          fileSizeLimit: IMAGE_SIZE_LIMIT,
          inputRef: artworkFieldRef,
        },
      },
      {
        name: 'tracks',
        label: 'Tracks',
        type: 'fileUpload',
        value: [],
        required: true,
        inputProps: {
          inputRef: tracksFieldRef,
        },
      },
      {
        name: 'description',
        label: 'Description',
        type: 'textarea',
        placeholder: 'Enter a description for your release',
        required: true,
        value: '',
      },
      {
        name: 'tags',
        label: 'Tags',
        type: 'tags',
        placeholder: 'Enter tags',
        value: [],
        required: false,
      },
      {
        name: 'price',
        label: 'Price',
        type: 'number',
        placeholder: 'Enter a price',
        required: true,
        value: undefined,
        inputProps: {
          currency: isUsdc && '$',
          min: 0,
          childInput: {
            type: 'checkbox',
            label: 'Free',
            value: false,
          },
        },
      },
      {
        name: 'editionType',
        label: 'Edition Type',
        type: 'radio',
        inputProps: {
          inputSelectOptions: [
            { label: 'Open edition', value: 'openEdition' },
            { label: 'Limited edition', value: 'limitedEdition' },
          ],
          styleOverride: ' ',
        },
        required: true,
        value: 'openEdition',
      },
      {
        name: 'editionSize',
        label: 'Edition Size',
        type: 'conditional',
        required: true,
        value: undefined,
        inputProps: {
          inputSelectOptions: [
            { label: '5', value: '5' },
            { label: '10', value: '10' },
            { label: '50', value: '50' },
            { label: '100', value: '100' },
            { label: 'Custom', value: 'custom' },
          ],

          type: 'select',
          dependentOn: 'editionType',
          dependencyTrigger: 'limitedEdition',
        },
        placeholder: 'Select an amount',
      },
      {
        name: 'customEditionSize',
        label: undefined,
        type: 'conditional',
        placeholder: 'Enter an amount',
        value: undefined,
        inputProps: {
          type: 'number',
          dependentOn: 'editionSize',
          dependencyTrigger: 'custom',
          secondaryDependency: 'editionType',
          secondaryDependencyTrigger: 'limitedEdition',
          styleOverride: 'mb-20 lg:mb-28 ',
        },
      },
      {
        name: 'catalogNumber',
        label: 'Catalog No.',
        type: 'text',
        value: undefined,
        placeholder: 'Enter a catalog number',
        required: true,
      },
      {
        name: 'scheduleRelease',
        label: 'Schedule release',
        type: 'checkbox',
        required: false,
        value: false,
        hide: true,
      },
      {
        name: 'date',
        label: 'Release Date',
        type: 'conditional',
        placeholder: 'Select a date',
        required: true,
        value: undefined,
        inputProps: {
          type: 'date',
          dependentOn: 'scheduleRelease',
          dependencyTrigger: true,
        },
      },
      {
        name: 'conversionQuality',
        label: 'Conversion Quality',
        type: 'conditional',
        placeholder: 'Select a quality',
        required: false,
        value: '320',
        hint: ' Please select a conversion quality. We will convert your lossless files to be publicly streamed on Nina.',
        inputProps: {
          inputSelectOptions: [
            { label: 'High: 320 kbps', value: '320', disabled: false },
            {
              label: 'Medium: 256 kbps (Coming Soon)',
              value: '256',
              disabled: true,
            },
            {
              label: 'Low: 192 kbps (Coming Soon)',
              value: '192',
              disabled: true,
            },
          ],
          type: 'select',
          dependencyTrigger: false,
        },
      },
      {
        name: 'isUsdc',
        label: 'is Usdc',
        type: 'checkbox',
        required: false,
        value: isUsdc,
        hide: true,
      },
      {
        name: 'review',
        type: 'review',
        flowType: FlowType.Release,
      },
    ] as FormField[]
  }

  const [fields, setFields] = useState(defaultReleaseCreateFields())
  const { createRelease } = useContext(FileService.Context)
  const router = useRouter()
  useEffect(() => {
    if (formSteps) {
      setHeaderSteps(Object.keys(formSteps).length)
    }
  }, [formSteps])

  useEffect(() => {
    const handleFetchAccountHubs = async () => {
      if (wallet && wallet.publicKey) {
        const { hubs } = await fetchHubsForAccount({
          publicKey: wallet.publicKey.toBase58(),
          pagination: {
            limit: 50,
            offset: 0,
          },
          withAccountData: false,
        })

        setAccountHubs(hubs)
      }
    }

    handleFetchAccountHubs()
  }, [wallet])

  useEffect(() => {
    const handleSetInitialHub = async () => {
      if (wallet && wallet.publicKey) {
        const { published } = await fetchReleasesPublishedByAccount({
          publicKey: wallet.publicKey.toBase58(),
          pagination: { limit: 6, offset: 0, sort: 'desc' },
          withAccountData: true,
        })

        if (published[0]?.hub) {
          localStorage.setItem('lastHubUpload', published[0].hub.publicKey)
        }
      }

      setFields((prevState) => {
        const lastHubUpload = localStorage.getItem('lastHubUpload')

        const fieldsCopy = prevState.map((field) => {
          if (field.name === 'hub' && field.inputProps !== undefined) {
            field.inputProps.inputSelectOptions = accountHubs.map(
              (hub: Hub) => {
                return {
                  label: hub.data.displayName,
                  value: hub.publicKey,
                }
              },
            )

            if (lastHubUpload) {
              field.value = lastHubUpload
            }

            if (accountHubs.length > 0 && !lastHubUpload) {
              field.value = accountHubs[0]?.publicKey
            }
          }

          return field
        })

        return fieldsCopy
      })
    }

    handleSetInitialHub()
  }, [accountHubs])

  useEffect(() => {
    const handleSetInitialCatalogNumber = async () => {
      if (wallet && wallet.publicKey) {
        const { published } = await fetchReleasesPublishedByAccount({
          publicKey: wallet.publicKey.toBase58(),
          pagination: {
            limit: 50,
            offset: 0,
          },
          withAccountData: false,
        })

        const lastReleaseCatalogNumber = published[0]?.metadata.symbol

        const localStorageLastReleaseCatalogNumber = localStorage.getItem(
          'lastReleaseCatalogNumber',
        )

        if (published.length > 0) {
          if (!localStorageLastReleaseCatalogNumber) {
            localStorage.setItem(
              'lastReleaseCatalogNumber',
              lastReleaseCatalogNumber,
            )
            setFields((prevState) => {
              const fieldsCopy = prevState.map((field) => {
                if (field.name === 'catalogNumber') {
                  field.value = formatAndIncrementCatalogNumber(
                    lastReleaseCatalogNumber,
                  )
                }

                return field
              })

              return fieldsCopy
            })
          } else {
            setFields((prevState) => {
              const fieldsCopy = prevState.map((field) => {
                if (field.name === 'catalogNumber') {
                  field.value = formatAndIncrementCatalogNumber(
                    localStorageLastReleaseCatalogNumber,
                  )
                }

                return field
              })

              return fieldsCopy
            })
          }
        } else {
          setFields((prevState) => {
            const fieldsCopy = prevState.map((field) => {
              if (field.name === 'catalogNumber') {
                field.value = undefined
              }

              return field
            })

            return fieldsCopy
          })
        }
      }
    }

    handleSetInitialCatalogNumber()
  }, [wallet])

  useEffect(() => {
    if (!isParentOpen) {
      setFields(defaultReleaseCreateFields())
      setCurrentStep(0)
    }
  }, [isParentOpen])

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement

    const isRadio =
      e.target instanceof HTMLInputElement && e.target.type === 'radio'

    const isCheckbox =
      e.target instanceof HTMLInputElement && e.target.type === 'checkbox'

    if (!isRadio && !isCheckbox) {
      e?.preventDefault()
    }

    setHasUnsavedChanges(true)
    setFields((prevFields: FormField[]) => {
      const updatedFields = prevFields.map((field) => {
        //set title field on tracks for multi track releases

        if (target.name === 'trackTitle') {
          const tracks = fields.find((field) => field.name === 'tracks')

          if (tracks) {
            tracks.value[target.id].title = target.value
          }
        }

        if (field.name === target.name) {
          if (target.type === 'checkbox') {
            const { inputProps } = field

            if (field.name === 'scheduleRelease' && field.value === true) {
              // reset date field if schedule release is unchecked
              const dateField = fields.find((field) => field.name === 'date')

              if (dateField) {
                dateField.value = undefined
              }

              return { ...field, value: false }
            }

            if (
              field.type === 'number' &&
              inputProps?.childInput?.type === 'checkbox'
            ) {
              // if the field has a checkbox then update if it is checked or not
              // additionally, update its value to the checkbox value if it is checked

              const updatedInputProps = {
                ...field,
                value: target.checked ? '0' : '',
                inputProps: {
                  ...field.inputProps,
                  childInput: {
                    ...field.inputProps?.childInput,
                    value: target.checked,
                  },
                },
              }

              return updatedInputProps
            }

            return { ...field, value: target.checked }
          }

          if (field.name === 'tracks') {
            const { files } = target

            if (!files) return null

            const fileListArray = Array.from(files)

            const hasWavFiles = fileListArray.some(
              (file) => file.type === 'audio/wav',
            )

            const conversionQualityField = fields.find(
              (field) => field.name === 'conversionQuality',
            )

            if (hasWavFiles === true) {
              if (conversionQualityField && conversionQualityField.inputProps) {
                conversionQualityField.inputProps.dependencyTrigger = true
              }
            }

            let index = field.value.length + 1
            for (const file of target.files as FileList) {
              const extendedFile = file as FileExtended

              if (!extendedFile.trackNumber) {
                extendedFile.trackNumber = index
              }

              if (!extendedFile.title) {
                extendedFile.title = '' // PLEASE DOUBLE CHECLK THIS
              }

              index++
            }

            //advance next step
            setCurrentStep(1)

            return {
              ...field,
              value: [
                ...(field.value as FileList),
                ...(target.files as FileList),
              ],
            }
          }

          const updatedError = validateField(field, e)

          return { ...field, value: target.value, error: updatedError }
        }

        return field
      })

      return updatedFields as FormField[]
    })
  }

  const handleTagFieldChange = (name: string, tags: string[]) => {
    setFields((prevState: FormField[]) => {
      const updatedFields = prevState.map((field: FormField) => {
        if (field.name === 'tags') {
          return { ...field, value: tags }
        }

        return field
      })

      return updatedFields
    })
  }

  const removeFileFromList = (index: number) => {
    setFields((prevState) => {
      const updatedFields = prevState.map((field: FormField) => {
        if (field.name === 'tracks') {
          field.value.splice(index, 1)

          field.value.forEach(
            (file: FileExtended, index: number) =>
              (file.trackNumber = index + 1),
          )
        }

        return field
      })

      return updatedFields
    })
  }

  const reorderFileInList = (file: FileExtended, from: number, to: number) => {
    setFields((prevState) => {
      const updatedFields = prevState.map((field: FormField) => {
        if (field.name === 'tracks') {
          const index = field.value.indexOf(file)

          if (index !== -1) {
            if (file.title === undefined) {
              file.title = ''
            }
          }

          file.trackNumber = index + 1
          const fileToMove = field.value[from]
          field.value.splice(from, 1)
          field.value.splice(to, 0, fileToMove)
          field.value.forEach(
            (file: FileExtended, index: number) =>
              (file.trackNumber = index + 1),
          )
        }

        return field
      })

      return updatedFields
    })
  }

  const imageFieldReset = () => {
    setFields((prevFields) => {
      const prevFieldsCopy = [...prevFields]

      const artworkField = prevFieldsCopy.find(
        (field) => field.name === 'artwork',
      )

      if (artworkField) {
        artworkField.value = undefined
        artworkField.error = undefined
      }

      return prevFieldsCopy
    })
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const data = convertFieldsToObject(
      fields,
      FlowType.Release,
    ) as ReleaseCreateFormFieldData

    if (data.artwork.file.size > IMAGE_SIZE_LIMIT) {
      alert('Image file is too large')

      return
    } else {
      createRelease(data)
      localStorage.setItem('lastReleaseCatalogNumber', data.catalogNumber)
      localStorage.setItem('lastHubUpload', data.hub)
      router.push('/releases/uploading')
      closeModal()
      setFields(defaultReleaseCreateFields())
      setCurrentStep(0)
    }
  }

  const handleCroppedImage = (data: object) => {
    setFields((prevFields) => {
      const prevFieldsCopy = [...prevFields]

      const artworkField = prevFieldsCopy.find(
        (field) => field.name === 'artwork',
      )

      if (artworkField) {
        artworkField.value = data
      }

      return prevFieldsCopy
    })
  }

  return (
    <>
      <Form
        formSteps={formSteps}
        fields={fields}
        setFields={setFields}
        buttonLabel="Continue"
        title={'Edit Release'}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleTagFieldChange={handleTagFieldChange}
        handleCroppedImage={handleCroppedImage}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isInModal={true}
        removeFileFromList={removeFileFromList}
        reorderFileInList={reorderFileInList}
        accountHubs={accountHubs}
        imageFieldReset={imageFieldReset}
      />
    </>
  )
}
